import { useEffect, useState } from "react";
import "./index.css";
import {
  FaTrophy,
  FaClipboardList,
  FaUserEdit,
  FaLock,
  FaPhoneAlt,
} from "react-icons/fa";
import profile from "../../images/profile.png";
import Cookies from "js-cookie";
import { MdOutlineMailOutline } from "react-icons/md";

// const apiUrl = `http://localhost:3000/api`;
const apiUrl = `https://qui-ws.azurewebsites.net/api`;

const token = Cookies.get("jwtToken");
const Profile = () => {
  const [userInfo, setUserInfo] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    role: "",
    completedQuizzes: 0,
    completedModules: 0,
    modules: [],
  });

  useEffect(() => {
    const fetchData = async () => {
const token = Cookies.get("jwtToken");
      try {
        const response = await fetch(`${apiUrl}/user`, {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();

        setUserInfo({
          fullName: `${data.firstName} ${data.lastName}`,
          email: data.email,
          phoneNumber: data.phoneNumber,
          role: data.role,
          completedQuizzes: data.completedQuizzes.length,
          completedModules: data.completedModules.length,
          modules: data.completedModules.map((module) => module.moduleId),
        });
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="profile-container">
      <section className="profile-header">
        <div className="profile-bg"></div>
        <div className="profile-info">
          <img className="profile-avatar" src={profile} alt="User Avatar" />
          <h1 className="profile-name">{userInfo.fullName}</h1>

          <p className="profile-role" style={{ fontSize: "20px" }}>
            {userInfo.role.charAt(0).toUpperCase() + userInfo.role.slice(1)}
          </p>
        </div>
      </section>

      <section className="profile-details">
        <h2 className="details-title">About Me</h2>
        <div className="profile-stats">
          <div className="stat-card">
            <FaClipboardList className="stat-icon" />
            <h3>{userInfo.completedQuizzes}</h3>
            <p>Quizzes Completed</p>
          </div>
          <div className="stat-card">
            <FaTrophy className="stat-icon" />
            <h3>{userInfo.completedModules}</h3>
            <p>Modules Completed</p>
          </div>
        </div>
      </section>

      <section className="profile-achievements">
        <h2 className="details-title">Completed Modules</h2>
        <div className="achievements-list">
          {userInfo.modules.map((module, index) => (
            <div key={index} className="achievement-card">
              <p>{module}</p>
            </div>
          ))}
        </div>
      </section>

      <section className="profile-actions">
        <h2 className="details-title">Profile Settings</h2>
        <div className="profile-stats">
          <div className="stat-card">
            <MdOutlineMailOutline className="stat-icon" />
            <p className="profile-email">{userInfo.email}</p>
          </div>
          <div className="stat-card">
            <FaPhoneAlt className="stat-icon" />
            <p className="profile-phone">{userInfo.phoneNumber}</p>
          </div>
        </div>
        <div className="action-cards" style={{ marginTop: "10px" }}>
          <div className="action-card">
            <button>
              <FaLock /> Change Password
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Profile;
