import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

// const apiUrl = `http://localhost:3000/api`;
const apiUrl = `https://qui-ws.azurewebsites.net/api`;

const Dashboard = () => {
  const [quizzes, setQuizzes] = useState([]);
  const [selectedQuizId, setSelectedQuizId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [completedQuizzes, setCompletedQuizzes] = useState([]);
  const [completedModules, setCompletedModules] = useState([]);
  const navigate = useNavigate();

  // Helper function to get the CSS class based on status
  const getStatusLabelClass = (status) => {
    switch (status) {
      case "active":
        return "status-label active";
      case "inactive":
        return "status-label inactive";
      default:
        return "status-label";
    }
  };

  // Fetch quizzes data from API
  useEffect(() => {
    const fetchQuizzes = async () => {
      try {
        const jwtToken = Cookies.get("jwtToken");
        if (!jwtToken) {
          console.error("No JWT token found in Cookies");
          return;
        }

        const response = await fetch(`${apiUrl}/quizzes/attempt/quizzes`, {
          method: "GET",
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${jwtToken}`,
          },
        });

        if (!response.ok) {
          console.error(
            "Failed to fetch quizzes:",
            response.status,
            response.statusText
          );
          return;
        }

        const data = await response.json();
        console.log("Fetched quizzes data:", data);

        const filteredQuizzes = data.filter((quiz) => quiz.status !== "hold");
        console.log("Filtered quizzes:", filteredQuizzes);
        setQuizzes(filteredQuizzes);
      } catch (error) {
        console.error("Error fetching quizzes:", error);
      }
    };

    fetchQuizzes();
  }, []);

  // Fetch user data to get completed quizzes and modules
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const jwtToken = Cookies.get("jwtToken");
        if (!jwtToken) {
          console.error("No JWT token found in Cookies");
          return;
        }

        const response = await fetch(`${apiUrl}/user`, {
          method: "GET",
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${jwtToken}`,
          },
        });

        if (!response.ok) {
          console.error(
            "Failed to fetch user data:",
            response.status,
            response.statusText
          );
          return;
        }

        const userData = await response.json();
        console.log("Fetched user data:", userData);

        // Extract the completed quizzes and modules from user data
        const completedQuizData = userData.completedQuizzes.map((quiz) => ({
          quizId: quiz.quizId,
          score: quiz.score,
        }));
        const completedModuleIds = userData.completedModules.map(
          (module) => module.moduleId
        );
        setCompletedQuizzes(completedQuizData);
        setCompletedModules(completedModuleIds);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, []);

  // Handle Start Quiz button click
  const handleStartQuiz = async (quizId) => {
    try {
      const jwtToken = Cookies.get("jwtToken");
      if (!jwtToken) {
        console.error("No JWT token found in Cookies");
        return;
      }

      const response = await fetch(`${apiUrl}/user`, {
        method: "GET",
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
      });

      if (!response.ok) {
        console.error(
          "Failed to fetch user data:",
          response.status,
          response.statusText
        );
        return;
      }

      const userData = await response.json();
      console.log("Fetched user data:", userData);

      const selectedQuiz = quizzes.find((quiz) => quiz.quizId === quizId);
      if (!selectedQuiz) {
        console.error("Quiz not found");
        return;
      }

      const requiredModuleId = selectedQuiz.linkedModuleId;
      console.log(requiredModuleId);
      const hasCompletedModule = completedModules.includes(requiredModuleId);

      if (hasCompletedModule) {
        setSelectedQuizId(quizId);
        setIsModalOpen(true);
      } else {
        alert("You have not completed the related module yet.");
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  // Handle accepting quiz instructions
  const handleAcceptInstructions = () => {
    setIsModalOpen(false);
    navigate(`/quiz/${selectedQuizId}`);
  };

  // Handle closing the modal
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="dashboard">
      <div className="categories-section">
        <h2 className="section-title">Choose Your Quizzes</h2>
        <div className="quiz-categories">
          {quizzes.length > 0 ? (
            quizzes.map((quiz) => {
              const completedQuiz = completedQuizzes.find(
                (q) => q.quizId === quiz.quizId
              );
              const isModuleCompleted = completedModules.includes(quiz.linkedModuleId);
              const isLearningPathIncomplete = !isModuleCompleted && quiz.status === "closed";

              return (
                <div key={quiz.quizId} className="category-card">
                  <div className="category-content">
                    <h3>{quiz.title}</h3>
                    <p className={getStatusLabelClass(quiz.status)}>
                      {quiz.status}
                    </p>
                    <br />
                    {completedQuiz ? (
                      <p className="attempted-label">
                        Attempted - Score: {completedQuiz.score} /5
                      </p>
                    ) : isLearningPathIncomplete ? (
                      <p className="learning-path-incomplete">
                        Learning Path Incomplete
                      </p>
                    ) : (
                      <button onClick={() => handleStartQuiz(quiz.quizId)}>
                        Start Learning
                      </button>
                    )}
                  </div>
                </div>
              );
            })
          ) : (
            <div className="spinner-container">
              <div className="spinner"></div>
            </div>
          )}
        </div>
      </div>

      {/* Modal for quiz instructions */}
      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h2>Quiz Instructions</h2>
            <p>Welcome to the quiz! Here are some basic instructions:</p>
            <ul>
              <li>You will have a total of 10 questions.</li>
              <li>Each question has multiple-choice answers.</li>
              <li>You can skip questions if you are unsure.</li>
              <li>Your score will be displayed at the end.</li>
              <li>Good luck and have fun!</li>
            </ul>
            <button onClick={handleAcceptInstructions}>Accept and Start</button>
            <button onClick={handleCloseModal}>Cancel</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
