import React, { useState, useEffect } from "react";
import "./index.css";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners"; // Import the spinner

// const apiUrl = `http://localhost:3000/api`;
const apiUrl = `https://qui-ws.azurewebsites.net/api`;

const Login = () => {
  const [isRegister, setIsRegister] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showModal, setShowModal] = useState(false); // New state for modal visibility
  const [loading, setLoading] = useState(false); // New state for loading
  const navigate = useNavigate();

  useEffect(() => {
    const jwt = Cookies.get("jwtToken");
    if (jwt !== undefined) {
      navigate("/");
    }
  }, [navigate]);

  const toggleForm = (event) => {
    event.preventDefault();
    setIsRegister(!isRegister);
    clearFields();
  };

  const clearFields = () => {
    setEmail("");
    setPassword("");
    setShowModal(false); // Ensure modal is closed when clearing fields
  };

  const handleRetry = () => {
    setShowModal(false); // Close modal on retry
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true); // Start loading

    if (isRegister) {
      alert("Registration is disabled in this mode.");
      setLoading(false); // Stop loading
    } else {
      // Fetch authentication using POST request to '/auth/login' endpoint
      try {
        const response = await fetch(`${apiUrl}/auth/login`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            phoneNumber: email,
            password: password,
          }),
        });

        const data = await response.json();

        if (response.ok) {
          // If login is successful, set the token in cookies
          Cookies.set("jwtToken", data.token, {
            expires: 30,
          });

          // Make a GET request to '/user' endpoint to fetch user details
          const userResponse = await fetch(`${apiUrl}/user`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${data.token}`,
            },
          });

          const userData = await userResponse.json();

          if (userResponse.ok) {
            if (userData.role === "nurse") {
              clearFields();
              navigate("/"); // Redirect to the home page
            } else {
              alert("Access restricted: Only nurses can access this page.");
              Cookies.remove("jwtToken"); // Remove the token if access is restricted
            }
          } else {
            // If fetching user details fails, show the modal with the error message
            setShowModal(true);
          }
        } else {
          // If login fails, show the modal with the error message
          setShowModal(true);
        }
      } catch (error) {
        console.error("Error logging in:", error);
        setShowModal(true); // Show modal on error
      } finally {
        setLoading(false); // Stop loading
      }
    }
  };

  return (
    <div className="login-container login-main-page">
      <img
        src="https://res.cloudinary.com/dbylngblb/image/upload/v1727508953/logo_wfnqks.svg"
        alt="logo-apollo"
        className="login-page-image"
      />
      <div className="login-box">
        <h2 className="login-title">{isRegister ? "Register" : "Login"}</h2>
        <form className="login-form" onSubmit={handleSubmit}>
          <input
            className="input-field"
            type="tel"
            placeholder="Phone Number"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            required
          />
          <input
            className="input-field"
            type="password"
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            required
          />
          <button className="submit-btn" type="submit" disabled={loading}>
            {loading ? <ClipLoader size={20} color="#ffffff" /> : isRegister ? "Register" : "Login"}
          </button>
        </form>
        {/* <p className="toggle-text">
          {isRegister ? "Already have an account?" : "Don't have an account?"}
          <a href="#" className="toggle-link" onClick={toggleForm}>
            {isRegister ? "Login here" : "Register here"}
          </a>
        </p> */}
      </div>

      {/* Modal for wrong credentials */}
      {showModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <div className="wrong-bg">
              <h3>Wrong Credentials</h3>
              <p>Invalid username or password</p>
            </div>
            <button className="retry-btn" onClick={handleRetry}>
              Retry
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;
